import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The following tables list the fields of IVR Nodes data source that you can use in rules, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <h2>{`Contact Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the visit.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (ms)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration in mili-seconds of the interaction in the IVR node.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Executions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of executions of a node.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Exit option`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR node where the contact ended the conversation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Fallbacks (Maximum executions)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum number of executions before using fallback.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Fallbacks (Time limit)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time limit before using the fallback.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the IVR that handled the interaction. For example, `}<inlineCode parentName="td">{`123456`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the IVR that handled the interaction. For example, `}<inlineCode parentName="td">{`Support`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Node ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifier of the IVR node.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Node name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the IVR node.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Node type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of node.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of the IVR nodes that handled the interaction before it was ended or delivered to an operator.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Usage of DTMF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`Yes`}</li><li>{`No`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Usage of voice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`Yes`}</li><li>{`No`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Webcare ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifier of the client.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      